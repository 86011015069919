<template>
  <div>
    <article>
      <h1 class="center">隐私政策</h1>
      <p style="text-align: right">公布时间：2024年【5】月【13】日</p>
      <p style="text-align: right">生效时间：2024年【5】月【13】日</p>
      <section>
        <h3>提示条款</h3>
        <p>
          成都开心音符科技有限公司高度重视个人信息的保护，在您使用快音平台（以下称为【快音】)提供的服务时，我们将按照本隐私政策收集、使用您和您的最终用户（即“您的产品的最终用户”或“您的产品的终端用户”，以下同）的个人信息。我们希望通过本隐私政策向您清晰地介绍我们对您和您的最终用户的个人信息的处理方式，因此我们建议您完整地阅读本隐私政策，以帮助您了解维护自己隐私权的方式。
        </p>
        <p>
          本隐私政策适用于【快音】提供的各项服务，您访问【快音】平台、使用【快音】提供的服务，包括但不限于我们提供的网站、SDK、API、代码、工具以及不断创新研发的产品及服务，均适用本隐私政策。
        </p>
        <p>如您允许我们与第三方网站共享信息，则同时需受第三方网站服务条款和隐私政策的约束。</p>
        <p>
          请您注意，我们已经尽量按照我国最新有关法律、法规、部门规章、规范性文件（为了便于表述，以下简称“法律法规”），参照最新的国家标准制定并执行本政策。但由于前述规定更迭频繁，不同部门、人员对同一规定可能存在诸多理解等限制。若本政策规定内容与我国届时有效的法律法规规定存在不一致或矛盾之处，应以我国届时有效的法律法规之规定为准，我们亦将按照届时有效的法律法规之有关规定处理您的个人信息并保障您的个人信息权益。
        </p>
        <p>
          在使用【快音】产品或服务前，请您务必仔细阅读并透彻理解本政策，如果您不同意本隐私政策的内容，您应停止使用【快音】服务。
        </p>
      </section>
      <section>
        <h4>本隐私政策将帮助您了解以下内容：</h4>
        <p>一、我们如何收集您以及您最终用户的个人信息</p>
        <p>二、我们如何使用您以及您最终用户的个人信息</p>
        <p>三、我们如何保护您以及您最终用户的个人信息</p>
        <p>四、本隐私政策如何更新</p>
        <p>五、如何联系我们</p>
        <p>六、隐私政策的适用范围及法律</p>
        <p>七、第三方SDK隐私政策</p>
        <p>八、争议解决</p>
      </section>
      <section>
        <h4>一、 我们如何收集您以及您最终用户的个人信息</h4>
        <p>我们收集信息是为了更好、更优、更准确的完成您所选择的服务。我们收集信息的方式如下：</p>
        <h5>1、您向我们提供的信息</h5>
        <ul>
          <li>
            当您开通【快音】服务及您在使用【快音】提供的相关服务时填写、提交或接入的信息，包括您的联系电话、电子邮箱、企业信息、产品信息、应用程序名称相关数据等。
          </li>
          <li>我们收集这些信息是为了向您更好提供服务，为了实现这一目的，我们会把您的信息用于下列用途：</li>
          <li>（1）广告投放及广告监测归因、反作弊</li>
          <li>（2）向您提供【快音】各项服务，并维护、改进这些服务。</li>
          <li>（3）联系您解决问题。</li>
          <li>（4）经您许可的其他用途。</li>
        </ul>
        <h5>2、广告商业化服务过程中收集的信息</h5>
        <p>
          当您在网站、应用程序或其他数字媒体上查看通过【快音】发布的广告时，我们可能会收集有关您的设备以及您与广告交互方面的信息。这些信息能让我们向您提供广告，改进我们的服务，包括在您使用与我们合作的其他站点和应用程序时识别您的设备。
        </p>
        <h5>（1）程序化广告服务</h5>
        <p>
          【快音】广告服务帮助媒体填充广告实现广告变现和优化，当您在产品里集成了【快音】广告SDK/API后，媒体设备相关信息会通过广告SDK收集和传送数据到我们的服务器。
        </p>
        <p><span class="bold">【快音】广告SDK/API</span>仅会出于以下目的，收集和使用您的个人信息:</p>
        <section>
          <div class="table">
            <div class="t-head">
              <ul>
                <li v-for="(item, index) in option.column" :key="index">{{ item.label }}</li>
              </ul>
            </div>
            <div class="t-body" v-for="(item, index) in tableData" :key="index">
              <ul>
                <li v-for="(context, i) in option.column" :key="i">
                  <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                  <span v-else class="align-start"
                    ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                      item[context.prop]
                    }}</a>
                    <a
                      :href="link"
                      v-for="(link, index) in item[context.prop]"
                      v-show="Array.isArray(item[context.prop])"
                      :key="index"
                      >{{ link }} <br
                    /></a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <h5>（2）【快音】媒体管理平台</h5>
        <p>
          在您使用我们服务过程中，为识别账号异常状态、设备异常状态、了解和/或优化产品适配性、向您提供更契合您需求的页面展示和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息。在您使用【快音】服务，或访问【快音】平台所涉各网站时，【快音】自动接收并记录您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据。
        </p>
      </section>

      <section>
        <h4>二、 我们如何使用您以及您最终用户的个人信息</h4>
        <h5>1、 处理</h5>
        <p>我们将收集到的有关您的设备信息用来：</p>
        <ul>
          <li>• 在您的设备上显示广告，其中可能包括根据您的兴趣、偏好和位置进行定制的基于兴趣定向广告；</li>
          <li>• 投标投放广告并确定哪些广告最有效；</li>
          <li>
            •
            分析并向我们提供的需求合作伙伴或广告主提供关于广告和投放有效性的报告，包括基于我们确定的同一人持有的不同类型的设备。我们或我们聘请的第三方提供此项服务时，可以使用跨设备数据提供基于兴趣定向广告服务，并跨多个移动设备（包括OTT应用程序和智能电视）向用户提供定制广告；
          </li>
          <li>• 避免向您展示太多相同的广告；</li>
          <li>• 建立细分的主要人群标签，使我们的客户和合作伙伴能够更有针对性地提供广告和产品；</li>
          <li>• 改进我们的服务，协助我们的客户和合作伙伴改进他们的产品和服务；</li>
          <li>• 查明、阻止和防止欺诈、虚假流量或保护我们系统安全；</li>
          <li>• 我们可以处理您对调查的反馈意见，并与作为我们客户/营销人员的品牌共享反馈数据。</li>
        </ul>
        <h5>2、共享</h5>
        <p>
          发布者和供应合作伙伴。我们可以与发布者（应用程序或网站发布者）共享您的信息，以帮助他们了解用户如何与其应用程序和网站以及其应用程序和网站上的广告进行交互；
        </p>
        <p>
          需求合作伙伴和广告主。我们可以与使用我们营销平台的需求合作伙伴、品牌和广告主共享您的信息，让他们了解自己投放的效果；并帮助他们更有针对性地进行投放、提供产品或服务；
        </p>
        <p>
          营销合作伙伴。我们可以与数据合作伙伴和统计公司分享您的信息，前者通过提供数据完善服务帮助我们更好地了解您的偏好，后者帮助我们针对通过我们发布的广告进行归属和追踪（“营销合作伙伴”）；
        </p>
        <p>
          服务提供商。我们可能会与我们的第三方服务提供商分享我们收集到的有关您的个人信息。我们委托个人信息的服务提供商（处理程序）类别包括：信息技术及相关服务；欺诈检测、信息及服务；支付处理程序；客户服务提供商；以及进行服务提供的供应商；
        </p>
        <p>
          关联公司。我们可以与我们的关联公司共享个人信息，以便改进和/或其关联公司的服务，并以其他方式更好地为您和/或我们的客户提供所有服务，包括有效的内容管理；
        </p>
        <p>
          披露以保护我们或他人。如果我们真诚地认为，这样做是必要的或适当的：遵守执法或国家安全要求和法律程序，如法院命令或传票；保护您、我们或其他人的权利、财产或安全；执行我们的政策或合同；收取对我们的欠款；或协助调查或起诉涉嫌或实际的非法活动，我们可以访问、保存和向外部方披露我们存储的与您有关的任何信息；
        </p>
        <p>
          在兼并、出售或其他资产转让的情况下披露。如果我们参与了兼并、收购、融资尽职调查、重组、破产、接管、购买或出售资产，或向另一个提供商过渡服务，那么您的信息可能会在法律和/或合同允许的情况下作为此类交易的一部分被出售或转让；
        </p>
        <h5>3、公开披露</h5>
        <p>我们仅会在以下情况下，公开披露您或您最终用户的个人信息:</p>
        <p>（1）获得您或您最终用户明确同意；</p>
        <p>
          （2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您或您最终用户的个人信息。
        </p>
      </section>

      <section>
        <h4>三、 我们如何保护您以及您最终用户的个人信息</h4>
        <p>
          为了保障您以及您最终用户的信息安全，我们将按照行业通行标准、努力采取合理的物理、电子和管理方面的安全措施来保护您的信息，并尽最大合理努力使您以及您最终用户的信息不会被泄漏、毁损或者丢失。我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </p>
        <p>
          我们收集的信息保存于不向公众开放的安全运行环境之中。为避免未经授权访问您的信息，我们将其保存在有防火墙保护并可能加密的服务器之中。
        </p>
        <p>
          我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您及您最终用户的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括：
        </p>
        <p>1、完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的业务需求；</p>
        <p>2、保证我们为您提供服务的安全和质量；</p>
        <p>3、您是否同意更长的留存期间；</p>
        <p>4、是否存在保留期限的其他特别约定。</p>
        <p>在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
      </section>

      <section>
        <h4>四、 本隐私政策如何更新</h4>
        <p>
          1、为了给您提供更好的服务，我们的业务将不时变化，我们会适时对隐私政策进行修订，该等修订构成本隐私政策的一部分。
        </p>
        <p>
          2、未经您明确同意，我们不会削减您及您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          3、本隐私政策更新后，如果出现任何重大变更，我们将在变更生效前以发出通知的方式通知您。请定期查看我们的隐私政策，以确保您已阅读最新版本。
        </p>
      </section>
      <section>
        <h4>五、如何联系我们</h4>
        <p>如果您对本政策有任何疑问、意见或建议，可以通过如下任一方式联系我们：</p>
        <p>1. 加QQ号:1481328251。</p>
        <p>2. 添加微信号：Kuaiyin-C。</p>
        <p>3. 发送邮件至【kuaiyinkf@rings.net.cn】与我们个人信息保护负责人联系。</p>
        <p>
          4.
          除此之外，您还可以将相关函件邮寄至下列地址：【中国（四川）自由贸易试验区成都高新区天府三街199号B区16层B3-2、B4、B5、B6单元】
        </p>
        <p>我们将尽快审核所涉问题，并在验证您的身份后，于十五天内进行回复。</p>
      </section>

      <section>
        <h4>六、隐私政策的适用范围及法律</h4>
        <p>
          1、除某些特定服务外，我们所有的服务均适用《隐私政策》。特定服务将适用特定的隐私政策。该特定服务的隐私政策构成《隐私政策》的一部分。如任何特定服务的隐私政策与《隐私政策》有不一致之处，则适用特定服务的隐私政策。
        </p>
        <p>
          2、请您注意，《隐私政策》不适用于以下情况:
          通过我们的服务而接入的第三方服务(包括任何第三方网站)收集的信息;通过在我们服务中进行广告服务的其他公司和机构所收集的信息。但有充分证据证明系我们将您的个人信息披露给前述第三方的除外。
        </p>
      </section>

      <section>
        <h4>七、第三方SDK隐私政策</h4>
        <h4>本SDK会调用其他SDK，下表为其他SDK隐私内容及隐私链接。</h4>
      </section>

      <section>
        <ul>
          <li v-for="(privacy, pIndex) in privacyList" :key="pIndex">
            <h5 style="overflow: auto">
              <span class="bold">{{ privacy.name }}</span
              >，隐私协议地址：<a :href="privacy.link">{{ privacy.link }}</a>
            </h5>
            <div class="table">
              <div class="t-head">
                <ul>
                  <li v-for="(item, index) in option1.column" :key="index">{{ item.label }}</li>
                </ul>
              </div>
              <div class="t-body" v-for="(item, index) in privacy.tableData" :key="index">
                <ul>
                  <li v-for="(context, i) in option1.column" :key="i">
                    <span v-if="context.prop !== 'Links'">{{ item[context.prop] }}</span>
                    <span v-else class="align-start"
                      ><a :href="item[context.prop]" v-if="typeof item[context.prop] === 'string'">{{
                        item[context.prop]
                      }}</a>
                      <a
                        :href="link"
                        v-for="(link, index) in item[context.prop]"
                        v-show="Array.isArray(item[context.prop])"
                        :key="index"
                        >{{ link }} <br
                      /></a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <section>
        <h4>八、争议解决</h4>
        <p>1、如果您认为我们的个人信息处理行为损害了您的合法权益，您可向有关政府部门反映。</p>
        <p>
          2、本政策以及我们处理您的个人信息事宜引起的争议，您还可以通过向成都开心音符科技有限公司住所地有管辖权的人民法院提起诉讼的方式寻求解决。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'ADPrivacyProtocol',
    props: {},
    data() {
      return {
        option: {
          column: [
            {
              label: '个人信息类型',
              prop: 'PermissionName',
            },
            {
              label: '个人信息名称',
              prop: 'PermissionDescription',
            },
            {
              label: '使用目的',
              prop: 'Uses',
            },
          ],
        },
        option1: {
          column: [
            {
              label: '个人信息类型',
              prop: 'PermissionName',
            },
            {
              label: '个人信息字段',
              prop: 'PermissionDescription',
            },
            {
              label: '用途',
              prop: 'Uses',
            },
          ],
        },
        tableData: [
          {
            PermissionName: '设备信息',
            PermissionDescription:
              '必选信息：【仅android】设备品牌（制造商）、设备型号、APP系统版本、移动运营商、浏览器、操作系统版本、屏幕分辨率、屏幕密度',
            Uses: '广告投放及广告监测归因、反作弊',
          },
          {
            PermissionName: '标识符',
            PermissionDescription: '必选信息：【仅android】AndroidID、oaid 可选信息：【仅android】IMEI',
            Uses: '广告投放及广告监测归因、反作弊',
          },
          {
            PermissionName: '网络信息',
            PermissionDescription: '必选信息：【仅android】运营商信息、Wi-Fi状态、网络信号强度、IP地址',
            Uses: '广告投放、反作弊',
          },
          {
            PermissionName: '应用信息',
            PermissionDescription: '必选信息：【仅android】应用包名、版本号、应用前后台状态、应用商店版本号',
            Uses: '广告投放、反作弊',
          },
          {
            PermissionName: '传感器信息',
            PermissionDescription: '必选信息：【仅Android】加速度传感器、磁场传感器',
            Uses: '用于开屏、插屏、信息流、富媒体广告投放摇一摇交互方式、反作弊时监听传感器信息',
          },
          {
            PermissionName: '应用安装软件列表信息',
            PermissionDescription: '必选信息：【仅Android】从 API 接口读取，判断得知应用安装情况',
            Uses: '广告投放、反作弊',
          },
          {
            PermissionName: '广告信息',
            PermissionDescription: '必选信息：【仅Android产品交互数据，广告数据（展示、点击、转化等数据）',
            Uses: '广告投放数据统计分析、广告监测归因、反作弊',
          },
          {
            PermissionName: '性能信息',
            PermissionDescription: '必选信息：【仅Android】如崩溃数据、性能数据',
            Uses: '减少APP崩溃、保证服务器正常运行、提供稳定服务',
          },
        ],
        privacyList: [
          {
            name: '优量汇',
            link: 'https://e.qq.com/dev/help_detail.html?cid=2005&pid=5983',
            tableData: [
              {
                PermissionName: '粗略位置信息',
                PermissionDescription: '【仅 Android】基站、附近的WIFI',
                Uses: '广告投放',
              },
              {
                PermissionName: '设备/网络/系统信息',
                PermissionDescription:
                  '【双端】设备制造商、品牌、设备型号、操作系统版本、屏幕分辨率、屏幕方向、屏幕DPI、IP地址、时区、网络类型、运营商 【仅iOS】设备名称、系统启动时间、系统语言、磁盘空间、设备姿态、压力、加速度传感器等基础信息',
                Uses: '广告投放',
              },
              {
                PermissionName: '标识符',
                PermissionDescription:
                  '【仅Android】OAID【可选】 IMEI、Android_ID 【仅iOS】设备标识符（如IDFV、 IDFA）',
                Uses: '广告投放、广告归因、广告监测',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription:
                  '【双端】宿主应用的包名、版本号 【仅Android】 宿主应用的进程名称、运行状态、可疑行为',
                Uses: '广告投放、反作弊',
              },
              {
                PermissionName: '使用数据',
                PermissionDescription: '【双端】如产品交互数据、广告数据（如展示、点击、转化广告数据）',
                Uses: '广告主统计投放结果',
              },
              {
                PermissionName: '诊断数据',
                PermissionDescription: '【双端】如崩溃数据、性能数据',
                Uses: '最大程度减少App崩溃、确保服务器正常运行、提升可扩展性和性能',
              },
            ],
          },
          {
            name: '穿山甲',
            link: 'https://www.csjplatform.com/privacy',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '必选信息：【双端】设备品牌、型号、软件系统版本、屏幕密度、屏幕分辨率、设备语言、设备时区、sim卡信息（mcc&mnc）、CPU信息、可用存储空间大小等基础信息【仅Android】AndroidID、OAID【仅iOS】手机系统重启时间、磁盘总空间、系统总内存空间、IDFV 可选信息：【双端】设备MAC地址（如为iOS端，则仅适用于IOS3200以下版本）【仅Android】设备标识符（如IMEI、IMSI、ICCID、GAID（仅GMS服务）、MEID、硬件序列号build_serial，具体字段因软硬件版本不同而存在差异）【仅iOS】设备标识符（如IDFA，具体字段因软硬件版本不同而存在差异）',
                Uses: '广告投放及广告监测归因、反作弊',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription:
                  '【仅Android】开发者应用名、应用包名、版本号、应用前后台状态 您可以基于广告投放效果优化的目的需要选择是否向穿山甲提供： 【仅Android】应用列表信息',
                Uses: '广告投放、反作弊',
              },
              {
                PermissionName: '广告信息',
                PermissionDescription: '【双端】对广告的展示、点击及转化等交互数据',
                Uses: '广告监测归因、广告投放统计分析、反作弊',
              },
              {
                PermissionName: '网络信息',
                PermissionDescription: '【双端】运营商信息、Wi-Fi状态、网络信号强度、IP地址',
                Uses: '保证网络服务有效性及稳定性。其中，运营商信息、Wi-Fi状态还会用于广告投放广告投放、广告监测归因、广告反作弊',
              },
              {
                PermissionName: '传感器信息',
                PermissionDescription:
                  '【仅Android】线性加速度传感器、磁场传感器、旋转矢量传感器【双端】加速度传感器、陀螺仪传感器',
                Uses: '摇一摇、扭一扭等广告投放、广告反作弊',
              },
              {
                PermissionName: '位置信息',
                PermissionDescription:
                  '您可以基于广告投放效果优化的目的需要选择是否向穿山甲提供如下信息：【双端】精确位置信息、粗略位置信息（WiFi列表、WLAN接入点（如SSID，BSSID）、基站）',
                Uses: '广告定向投放及反作弊',
              },
              {
                PermissionName: '性能数据',
                PermissionDescription: '【双端】如崩溃数据、性能数据',
                Uses: '减少App崩溃、提供稳定可靠的服务',
              },
            ],
          },
          {
            name: '快手',
            link: 'https://u.kuaishou.com/home/detail/1220',
            tableData: [
              {
                PermissionName: '基础信息',
                PermissionDescription:
                  '设备品牌、设备型号、软件系统版本、存储信息、运营商信息、设备时区、设备语言、网络信息登记处信息。',
                Uses: '广告投放、广告归因、反作弊、安全',
              },
              {
                PermissionName: '设备标识',
                PermissionDescription: 'IMEI、MEID、OAID、AndroidID、IMSI、ICCID',
                Uses: '广告投放、广告归因、反作弊、安全',
              },
              {
                PermissionName: '位置信息',
                PermissionDescription: 'IP地址、MAC地址、GPS位置信息、基站信息、WiFi信息',
                Uses: '广告投放、广告归因、反作弊、安全',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription: '应用安装列表',
                Uses: '广告投放、广告归因、反作弊、安全',
              },
              {
                PermissionName: '其他信息',
                PermissionDescription: '传感器信息（加速度、重力、陀螺仪传感器、SIM 开激活信息',
                Uses: '广告投放、广告归因、反作弊、安全',
              },
            ],
          },
          {
            name: '百度',
            link: 'https://union.baidu.com/bqt/#/legal/policies',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '必选信息：设备品牌、型号、软件系统版本、分辨率、网络信号强度、磁盘总空间、系统内存空间、屏幕宽高，屏幕像素密度，系统版本号，设备厂商，设备型号，手机运营商、SIM卡信息、手机重启信息、手机系统更新时间、手机系统创建时间、IDFA（仅iOS端采集）、OAID等基础信息 可选信息：IMEI（仅Andriod端采集）；传感器信息（使用摇一摇等创新交互广告）；网络状态（判断网络状态、提供不同类型广告）',
                Uses: '基本功能：提供广告投放、广告监测、广告归因、反作弊功保障服务稳定安全',
              },
              {
                PermissionName: '开发者应用信息',
                PermissionDescription: '必选信息：应用包名、应用签名、应用前后台状态、运行进程信息',
                Uses: '基本功能：提供广告投放、广告监测、广告归因、反作弊功保障服务稳定安全',
              },
              {
                PermissionName: '位置信息',
                PermissionDescription: '可选信息：位置',
                Uses: '基本功能：提供广告投放、广告监测、广告归因、反作弊功保障服务稳定安全',
              },
              {
                PermissionName: '广告信息',
                PermissionDescription: '必选信息：操作记录、点击、展示、转化等交互数据',
                Uses: '基本功能：提供广告投放、广告监测、广告归因、反作弊功保障服务稳定安全',
              },
              {
                PermissionName: '性能数据',
                PermissionDescription: '必选信息：崩溃数据、性能数据',
                Uses: '基本功能：提供广告投放、广告监测、广告归因、反作弊功保障服务稳定安全',
              },
            ],
          },
          {
            name: '趣盟广告',
            link: 'https://cdn.aiclk.com/nsdk/res/open/qu-privacy-agreement.html',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '【Android】设备品牌、型号、软件系统版本相关信息、WiFi路由器MAC地址、设备的MAC地址、设备标识符（IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，具体字段因软硬件版本不同而存在差异）',
                Uses: '广告投放合作、广告归因、反作弊',
              },
              {
                PermissionName: '开发者应用信息',
                PermissionDescription: '开发者应用名、应用包名、版本号、应用安装列表等信息',
                Uses: '广告投放合作、广告归因、反作弊',
              },
              {
                PermissionName: '其他信息',
                PermissionDescription: '运营商信息、设备时区、设备语言',
                Uses: '广告投放合作、广告归因、反作弊',
              },
            ],
          },
          {
            name: '移动安全工作委员会',
            link: 'http://www.msa-alliance.cn/col.jsp?id=122',
            tableData: [
              {
                PermissionName: '品牌信息',
                PermissionDescription: '设备制造商 设备型号 设备品牌',
                Uses: '判断终端品牌，调用接口',
              },
              {
                PermissionName: '网络信息',
                PermissionDescription: '设备网络运营商名称',
                Uses: '判断是否为虚拟机环境',
              },
              {
                PermissionName: '应用软件信息',
                PermissionDescription: 'App包名及签名信息，或在对应应用商店的APPID',
                Uses: '生成VAID时判断是否为同一开发者',
              },
            ],
          },
          {
            name: '京准通',
            link: 'https://help-sdk-doc.jd.com/ansdkDoc/access_docs/privacy_policy.html',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '必选：【双端】设备制造商、设备型号、设备名称、设备工业名称、设备品牌、设备网络码、系统版本、SIM卡状态、运营商等基础设备信息【仅Android】系统指纹、SD卡状态信息 可选：您可以基于广告投放效果优化的目的需要选择是否向SDK提供如下信息：【双端】IP地址、网络信息【仅Android】设备标识符（如IMEI、OAID等）【仅iOS】设备标识符（如IDFA）',
                Uses: '广告投放、广告监测、广告归因、反作弊',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription: '必选：【仅Android】开发者应用名、应用包名、版本号、宿主应用的进程名称',
                Uses: '广告投放、反作弊',
              },
              {
                PermissionName: '广告信息',
                PermissionDescription: '必选：【双端】对广告的展示、点击及转化等交互数据',
                Uses: '广告监测归因、广告投放统计分析、反作弊',
              },
              {
                PermissionName: '位置信息',
                PermissionDescription: '可选：您可以基于广告投放效果优化的需要选择是否向SDK提供如下信息：地理位置信息',
                Uses: '个性化广告投放及反作弊',
              },
              {
                PermissionName: '性能数据',
                PermissionDescription: '必选：【双端】崩溃数据、性能数据',
                Uses: '减少App崩溃、提供稳定可靠的服务',
              },
              {
                PermissionName: '传感器信息',
                PermissionDescription: '必选：【双端】加速度传感器、陀螺仪传感器',
                Uses: '摇一摇广告投放、反作弊',
              },
            ],
          },
          {
            name: '科大讯飞',
            link: 'https://www.voiceads.cn/ai-help.html?id=220',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '设备硬件信息（匿名设备标识符OAID、国际移动设备识别码IMEI、iOS广告标识符IDFA、广告标识符ADID、中国广告协会互联网广告标识CAID、MAC地址及对应密文中的一种；客户端IP地址；设备生产商；设备横竖屏；设备型号；设备类型；设备屏幕的宽高度；屏幕分辨率）、设备软件信息（操作系统版本号、操作系统类型、系统启动标识、系统更新标识、系统更新时间、设备初始化时间、客户端ua）、语言信息（目前使用的语言-国家）、网络信息（网络运营商、联网类型、客户端ip）以及地理信息（地理位置-纬度、地理位置-经度，仅限于向固定地理区域投放广告时获取）',
                Uses: '广告监测与欺诈行为甄别',
              },
            ],
          },
          {
            name: '章鱼',
            link: 'http://doc.adintl.cn/#/zh-cn/guide/UsePrivacy',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '[双端] 设备品牌、设备型号、设备时区、设备语言、系统版本、开发者应用名、应用版本号、应用包名、UserAgent信息、屏幕高宽、屏幕方向、屏幕DPI信息、系统更新时间、开机时间、磁盘空间、物理内存 [仅Android] 设备ID（OAID、GAID）IMEI 用户授权才收集 USB调试模式 cpu运行状态 [仅ios] 设备ID（IDFA 用户授权才收集）',
                Uses: '于用户设备信息调整广告投放策略 基于用户设备信息提供统计分析服务 基于用户设备信息进行基础的反作弊分析 基于用户设备信息实现广告正常显示与交互功能的实现 通过崩溃信息，以此来优化代码缺陷，最大程度减少App崩溃 通过收集SDK运行过程中性能数据，以优化SDK的性能 统计广告数据，以用于广告主统计投放结果。包括不限于：请求、展示 、点击 、转化等，用于广告流程分析 获取信息只用于功能实现并不会收集与保存信息',
              },
              {
                PermissionName: '性能数据',
                PermissionDescription: '崩溃信息 性能数据',
                Uses: '于用户设备信息调整广告投放策略 基于用户设备信息提供统计分析服务 基于用户设备信息进行基础的反作弊分析 基于用户设备信息实现广告正常显示与交互功能的实现 通过崩溃信息，以此来优化代码缺陷，最大程度减少App崩溃 通过收集SDK运行过程中性能数据，以优化SDK的性能 统计广告数据，以用于广告主统计投放结果。包括不限于：请求、展示 、点击 、转化等，用于广告流程分析 获取信息只用于功能实现并不会收集与保存信息',
              },
              {
                PermissionName: '传感器信息',
                PermissionDescription:
                  '传感器信息（例如：重力传感器、加速度传感器、方向传感器、陀螺仪、压力传感器、重力传感器、线性加速度传感器等）',
                Uses: '于用户设备信息调整广告投放策略 基于用户设备信息提供统计分析服务 基于用户设备信息进行基础的反作弊分析 基于用户设备信息实现广告正常显示与交互功能的实现 通过崩溃信息，以此来优化代码缺陷，最大程度减少App崩溃 通过收集SDK运行过程中性能数据，以优化SDK的性能 统计广告数据，以用于广告主统计投放结果。包括不限于：请求、展示 、点击 、转化等，用于广告流程分析 获取信息只用于功能实现并不会收集与保存信息',
              },
              {
                PermissionName: '网络信息',
                PermissionDescription: '网络类型、网络状态、运营商',
                Uses: '于用户设备信息调整广告投放策略 基于用户设备信息提供统计分析服务 基于用户设备信息进行基础的反作弊分析 基于用户设备信息实现广告正常显示与交互功能的实现 通过崩溃信息，以此来优化代码缺陷，最大程度减少App崩溃 通过收集SDK运行过程中性能数据，以优化SDK的性能 统计广告数据，以用于广告主统计投放结果。包括不限于：请求、展示 、点击 、转化等，用于广告流程分析 获取信息只用于功能实现并不会收集与保存信息',
              },
            ],
          },
          {
            name: 'Tanx',
            link: 'https://terms.alicdn.com/legal-agreement/terms/suit_bu1_ali_mama_division/suit_bu1_ali_mama_division202202171548_47251.html?',
            tableData: [
              {
                PermissionName: '设备标识符',
                PermissionDescription:
                  '基于广告投放合作场景下实现广告投放功能，收集如下信息： 必选：（I0S 端）IDFA、 OAID（安卓端10及以上）、IMEI（安卓端10以下）和 OPEN UDID 可选：IMEI（安卓端 10及以上）',
                Uses: '广告投放，广告检测归因，反作弊',
              },
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '基于广告投放合作场景下实现广告投放功能，收集如下信息： 必选：（1）（双端）设备类型，设备型号，手机制造商，手机品牌，操作系统，操作系统版本，分辨率，当前网络接入类型，网络运营商，设备密度，屏幕方向；（2）（I0S 端）设备基础信息，如设备启动时间、设备语言、设备名称、设备内存和硬盘空间、系统更新时间、设备时区 可选：/',
                Uses: '广告投放，广告检测归因，反作弊',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription:
                  '基于广告投放合作场景下实现广告投放功能，收集如下信息： 必选：（双端）版本号，应用包名，加速传感器信息 可选：（I0S 端）应用安装信息',
                Uses: '广告投放，反作弊',
              },
            ],
          },
          {
            name: 'ubix',
            link: 'https://ubixai.com/ubix_sdk_privacy.html',
            tableData: [
              {
                PermissionName: '设备信息',
                PermissionDescription:
                  '必选信息：【双端】设备品牌、设备型号、设备名称、操作系统名称、操作系统版本、设备语言、屏幕密度、屏幕分辨率【仅iOS】IDFV 可选信息：【仅Android】设备标识符（如Android ID、OAID、IMEI、设备MAC地址）【仅iOS】IDFA',
                Uses: '变现分组策略及数据统计分析',
              },
              {
                PermissionName: '应用信息',
                PermissionDescription: '必选信息：【双端】开发者应用名、应用包名、应用版本号',
                Uses: '开发者账号信息一致性校验',
              },
              {
                PermissionName: '网络信息',
                PermissionDescription: '必选信息：【双端】网络类型【仅Android】运营商信息、Wi-Fi状态',
                Uses: '保证网络服务有效性及稳定性。其中，网络类型、Wi-Fi状态还会用于流量分组策略。',
              },
              {
                PermissionName: '位置信息',
                PermissionDescription: '可选信息：【双端】精确位置信息、粗略位置信息',
                Uses: '流量分组策略',
              },
              {
                PermissionName: '性能数据',
                PermissionDescription: '必选信息：【双端】如崩溃数据、性能数据',
                Uses: '减少App崩溃、提供稳定可靠的服务',
              },
              {
                PermissionName: '广告信息',
                PermissionDescription: '必选信息：【双端】对广告的展示、点击及转化等交互数据',
                Uses: '流量分组策略、广告变现策略',
              },
            ],
          },
        ],
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h1 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 20px;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 16px;
    text-align: center;
  }
  h4 {
    margin: 15px 0;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    text-indent: 2em;
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .t-head ul li {
    width: 30%;
    text-align: center;
    font-weight: bold;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 33%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
